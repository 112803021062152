export function getStringFromFetchRequest(input: RequestInfo | URL): string {
  let url = "";
  if (input instanceof URL) {
    url = input.href;
  } else if (typeof input === "string") {
    url = input;
  } else if (input instanceof Request) {
    url = input.url;
  }
  return url;
}
