import { apiClient } from "./api/client";

type MetricsPayload = {
  fetchDurations: FetchDuration[];
  errors: string[];
};

export type FetchDuration = {
  path: string;
  durationMs: number;
};

const interval = 10_000;
const initialDecrement = 12_000; // must be lesst than interval

export class AppMetrics {
  errors: string[] = [];
  fetchDurations: FetchDuration[] = [];

  private lastReportedAt: number = performance.now() - initialDecrement;

  started = false;

  addError(error: string) {
    if (typeof error != "string") {
      error = error + "";
    }
    console.log(`error added`);
    this.errors.push(error);

    this.syncMetrics();
  }

  addFetchDuration(fetchDuration: FetchDuration) {
    console.log(`fetch duration added`);
    this.fetchDurations.push(fetchDuration);
  }

  start() {
    if (this.started) return;

    setInterval(() => {
      void this.syncMetrics();
    }, interval);

    this.started = true;
  }

  async syncMetrics() {
    if (this.lastReportedAt < interval) return;

    this.lastReportedAt = performance.now();

    const payload = btoa(
      JSON.stringify({
        errors: this.errors,
        fetchDurations: this.fetchDurations,
      } as MetricsPayload),
    );

    if (!this.errors.length && !this.fetchDurations.length) return;

    const res = await apiClient.POST("/verify-session", {
      body: {
        session: payload,
      },
    });

    if (!res.error) {
      this.errors = [];
      this.fetchDurations = [];
    }
  }
}

export const appMetrics = new AppMetrics();
