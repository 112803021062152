import createClient from "openapi-fetch";
import { appMetrics } from "../metrics";
import { getStringFromFetchRequest } from "./apiUtils";
import type { paths } from "./v1";

const backendUrl = import.meta.env.PUBLIC_ENV__BACKEND_URL;
if (!backendUrl) {
  throw new Error("missing PUBLIC_ENV__BACKEND_URL");
}
// console.log(`api client created with api url: `, backendUrl);

export const apiClient = createClient<paths>({
  baseUrl: backendUrl,
  credentials: "include",
  fetch: async (input: RequestInfo | URL, init?: RequestInit) => {
    const url = getStringFromFetchRequest(input);

    const before = performance.now();
    const res = await fetch(input, init);
    const after = performance.now();

    const durationMs = after - before;

    appMetrics.addFetchDuration({
      path: url,
      durationMs,
    });

    console.log(`[CLIENT] [${init?.method ?? "GET"}] [${url}] took ${durationMs} ms`);

    return res;
  },
});

// const authMiddleware: Middleware = {
//   async onRequest(req, options) {
//     const user = getAuth().currentUser;
//     if (!user) {
//       return req;
//     }

//     const token = await user.getIdToken();
//     if (!token) {
//       return req;
//     }

//     req.headers.append("Authorization", "Bearer " + token);

//     const impersonatedUserId = appStore.getState().impersonatedUserId;
//     if (impersonatedUserId) {
//       req.headers.append("Impersonate", impersonatedUserId.toString());
//     }

//     return req;
//   },
// };

// api.use(authMiddleware);
